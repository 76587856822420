export const itemTypes = [
  {
    type: "ice-axes-bandit",
    name: "Cepíny SR Bandit",
    price1: 50,
    price2: 100,
    price3: 150,
    price4: 200,
    price5: 250,
    price6: 300,
  },
  {
    type: "ice-axes-drytool",
    name: "Cepíny na drytool",
    price1: 50,
    price2: 100,
    price3: 150,
    price4: 200,
    price5: 250,
    price6: 300,
  },

  {
    type: "ice-axes-old",
    name: "Starší cepíny Raveltik",
    price1: 20,
    price2: 40,
    price3: 60,
    price4: 80,
    price5: 100,
    price6: 120,
  },
  {
    type: "ice-axes-turistic",
    name: "Turistické cepíny",
    price1: 20,
    price2: 40,
    price3: 60,
    price4: 80,
    price5: 100,
    price6: 120,
  },
  {
    type: "crampons-semi",
    name: "Mačky RE - poloautomaty",
    price1: 30,
    price2: 60,
    price3: 90,
    price4: 120,
    price5: 150,
    price6: 180,
  },
  {
    type: "crampons-uni",
    name: "Mačky RE - uni",
    price1: 30,
    price2: 60,
    price3: 90,
    price4: 120,
    price5: 150,
    price6: 180,
  },
  {
    type: "crampons-petzl",
    name: "Mačky Petzl Plaut",
    price1: 30,
    price2: 60,
    price3: 90,
    price4: 120,
    price5: 150,
    price6: 180,
  },
  {
    type: "crampons-cassin",
    name: "Mačky Cassin",
    price1: 30,
    price2: 60,
    price3: 90,
    price4: 120,
    price5: 150,
    price6: 180,
  },
  {
    type: "crampons-frame",
    name: "Mačky automat/rámovky",
    price1: 30,
    price2: 60,
    price3: 90,
    price4: 120,
    price5: 150,
    price6: 180,
  },

  {
    type: "shovel",
    name: "Lopaty",
    price1: 20,
    price2: 40,
    price3: 60,
    price4: 80,
    price5: 100,
    price6: 120,
  },
  {
    type: "probe",
    name: "Sondy",
    price1: 30,
    price2: 60,
    price3: 90,
    price4: 120,
    price5: 150,
    price6: 180,
  },
  {
    type: "beacon-mammut",
    name: "Vyhledávače Mammut Barryvox",
    price1: 70,
    price2: 140,
    price3: 210,
    price4: 280,
    price5: 350,
    price6: 420,
  },
  {
    type: "drytoolboots",
    name: "Drytool boty",
    price1: 70,
  },
  // { type: "drill", name: "Vrtačka", price1: 50, price2: 100, price3: 200  },
];

export const itemList = [
  {
    id: "ice-axes-bandit-01",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 01 (L) - mix/drytool",
  },
  {
    id: "ice-axes-bandit-02",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 02 (K) - mix/drytool",
  },
  {
    id: "ice-axes-bandit-03",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 03 (L) - mix/drytool",
  },
  {
    id: "ice-axes-bandit-04",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 04 (K) - mix/drytool",
  },
  {
    id: "ice-axes-bandit-05",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 05 (L) - jen do ledu!",
  },
  {
    id: "ice-axes-bandit-06",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 06 (K) - jen do ledu!",
  },
  {
    id: "ice-axes-bandit-07",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 07 (L) - mix/drytool",
  },
  {
    id: "ice-axes-bandit-08",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 08 (K) - mix/drytool",
  },
  {
    id: "ice-axes-bandit-09",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 09 (L) - jen do ledu!",
  },
  {
    id: "ice-axes-bandit-10",
    type: "ice-axes-bandit",
    label: "Cepín - Bandit 10 (K) - jen do ledu!",
  },

  {
    id: "ice-axes-ergo-01",
    type: "ice-axes-drytool",
    label: "Cepín - Petzl Ergo 01 - drytool",
  },
  {
    id: "ice-axes-ergo-02",
    type: "ice-axes-drytool",
    label: "Cepín - Petzl Ergo 02 - drytool",
  },
  {
    id: "ice-axes-gladiator-01",
    type: "ice-axes-drytool",
    label: "Cepín - Gladiator G1 - drytool",
  },
  {
    id: "ice-axes-gladiator-02",
    type: "ice-axes-drytool",
    label: "Cepín - Gladiator G2 - drytool",
  },

  {
    id: "ice-axes-supercup-01",
    type: "ice-axes-old",
    label: "Cepín - Raveltik Demon - R3",
  },
  {
    id: "ice-axes-supercup-02",
    type: "ice-axes-old",
    label: "Cepín - Raveltik Demon - R4",
  },
  {
    id: "ice-axes-supercup-03",
    type: "ice-axes-old",
    label: "Cepín - Raveltik Super Cup - R1",
  },
  {
    id: "ice-axes-supercup-04",
    type: "ice-axes-old",
    label: "Cepín - Raveltik Super Cup - R2",
  },
  //TODO: v tech je trochu bordel

  {
    id: "ice-axes-RE-turistic-01",
    type: "ice-axes-turistic",
    label: "Turistický RE 01 60cm",
  },
  {
    id: "ice-axes-RE-turistic-02",
    type: "ice-axes-turistic",
    label: "Turistický RE 02 60cm",
  },
  {
    id: "ice-axes-RE-turistic-03",
    type: "ice-axes-turistic",
    label: "Turistický RE 03 60cm",
  },
  {
    id: "ice-axes-RE-turistic-04",
    type: "ice-axes-turistic",
    label: "Turistický RE 04 60cm",
  },
  {
    id: "ice-axes-RE-turistic-05",
    type: "ice-axes-turistic",
    label: "Turistický RE 05 60cm",
  },
  {
    id: "ice-axes-RE-turistic-06",
    type: "ice-axes-turistic",
    label: "Turistický RE 06 60cm",
  },
  {
    id: "ice-axes-RE-turistic-07",
    type: "ice-axes-turistic",
    label: "Turistický RE 07 60cm",
  },
  {
    id: "ice-axes-RE-turistic-08",
    type: "ice-axes-turistic",
    label: "Turistický RE 08 60cm",
  },
  {
    id: "ice-axes-RE-turistic-09",
    type: "ice-axes-turistic",
    label: "Turistický RE 09 55cm",
  },
  {
    id: "ice-axes-RE-turistic-10",
    type: "ice-axes-turistic",
    label: "Turistický RE 10 55cm",
  },
  {
    id: "ice-axes-RE-turistic-11",
    type: "ice-axes-turistic",
    label: "Turistický RE 11 65cm",
  },
  {
    id: "ice-axes-BD-turistic-12",
    type: "ice-axes-turistic",
    label: "Turistický cepín BD 12 65cm",
  },
  {
    id: "ice-axes-Raveltic-turistic-13",
    type: "ice-axes-turistic",
    label: "Turistický cepín Raveltik 13 65cm",
  },
  {
    id: "ice-axes-Raveltic-turistic-14",
    type: "ice-axes-turistic",
    label: "Turistický cepín Raveltik 14 50cm",
  },

  {
    id: "crampons-semi-01",
    type: "crampons-semi",
    label: "Mačky - RE - poloautomaty 01 - jen do ledu!",
  },
  {
    id: "crampons-semi-02",
    type: "crampons-semi",
    label: "Mačky - RE - poloautomaty 09",
  },
  {
    id: "crampons-semi-03",
    type: "crampons-semi",
    label: "Mačky - RE - poloautomaty 04 - jen do ledu!",
  },

  {
    id: "crampons-uni-01",
    type: "crampons-uni",
    label: "Mačky - RE - uni 13 - jen do ledu!",
  },
  {
    id: "crampons-uni-02",
    type: "crampons-uni",
    label: "Mačky - RE - uni 03 - jen do ledu!",
  },
  { id: "crampons-uni-03", type: "crampons-uni", label: "Mačky - RE - uni 07" },
  { id: "crampons-uni-04", type: "crampons-uni", label: "Mačky - RE - uni 08" },
  { id: "crampons-uni-05", type: "crampons-uni", label: "Mačky - RE - uni 06" },

  {
    id: "crampons-petzl",
    type: "crampons-petzl",
    label: "Mačky - Petzl Plaut",
  },
  {
    id: "crampons-cassin-01",
    type: "crampons-cassin",
    label: "Mačky - Cassin 10 - uni",
  },
  {
    id: "crampons-cassin-02",
    type: "crampons-cassin",
    label: "Mačky - Cassin 11 - uni",
  },
  {
    id: "crampons-cassin-03",
    type: "crampons-cassin",
    label: "Mačky - Cassin 12 - uni",
  },

  {
    id: "crampons-frame-petzl",
    type: "crampons-frame",
    label: "Mačky - Petzl",
  },
  { id: "crampons-frame-camp", type: "crampons-frame", label: "Mačky - Camp" },
  {
    id: "crampons-frame-raveltik-01",
    type: "crampons-frame",
    label: "Mačky - Raveltik",
  },
  {
    id: "crampons-frame-raveltik-02",
    type: "crampons-frame",
    label: "Mačky - Raveltik",
  },

  { id: "shovel-01", type: "shovel", label: "Lopata - Nicimpex 01" },
  { id: "shovel-02", type: "shovel", label: "Lopata - Nicimpex 02" },
  { id: "shovel-03", type: "shovel", label: "Lopata - Nicimpex 03" },
  { id: "shovel-04", type: "shovel", label: "Lopata - Nicimpex 04" },
  { id: "shovel-05", type: "shovel", label: "Lopata - Nicimpex 05" },
  { id: "shovel-06", type: "shovel", label: "Lopata - BD 06" },
  { id: "shovel-07", type: "shovel", label: "Lopata - BD 07" },
  { id: "shovel-08", type: "shovel", label: "Lopata - Nicimpex 08" },
  { id: "shovel-09", type: "shovel", label: "Lopata - MAMMUT 09" },
  { id: "shovel-10", type: "shovel", label: "Lopata - MAMMUT 10" },
  { id: "shovel-11", type: "shovel", label: "Lopata - MAMMUT 11" },
  { id: "shovel-12", type: "shovel", label: "Lopata - MAMMUT 12" },
  { id: "shovel-13", type: "shovel", label: "Lopata - MAMMUT 13" },
  { id: "probe-01", type: "probe", label: "Sonda - Nicimpex 01" },
  { id: "probe-02", type: "probe", label: "Sonda - Nicimpex 02" },
  { id: "probe-03", type: "probe", label: "Sonda - Nicimpex 03" },
  { id: "probe-04", type: "probe", label: "Sonda - Nicimpex 04" },
  { id: "probe-05", type: "probe", label: "Sonda - Nicimpex 05" },
  { id: "probe-06", type: "probe", label: "Sonda - MAMMUT 06" },
  { id: "probe-07", type: "probe", label: "Sonda - MAMMUT 07" },
  { id: "probe-08", type: "probe", label: "Sonda - MAMMUT 08" },
  { id: "probe-09", type: "probe", label: "Sonda - MAMMUT 09" },
  { id: "probe-10", type: "probe", label: "Sonda - MAMMUT 10" },
  { id: "probe-11", type: "probe", label: "Sonda - MAMMUT 11" },
  { id: "probe-12", type: "probe", label: "Sonda - MAMMUT 12" },
  {
    id: "beacon-mammut-01",
    type: "beacon-mammut",
    label: "Mammut Barryvox 01",
  },
  {
    id: "beacon-mammut-02",
    type: "beacon-mammut",
    label: "Mammut Barryvox 02",
  },
  {
    id: "beacon-mammut-03",
    type: "beacon-mammut",
    label: "Mammut Barryvox 03",
  },
  {
    id: "beacon-mammut-04",
    type: "beacon-mammut",
    label: "Mammut Barryvox 04",
  },
  {
    id: "beacon-mammut-05",
    type: "beacon-mammut",
    label: "Mammut Barryvox 05",
  },
  {
    id: "beacon-mammut-06",
    type: "beacon-mammut",
    label: "Mammut Barryvox 06",
  },
  {
    id: "beacon-mammut-07",
    type: "beacon-mammut",
    label: "Mammut Barryvox 07",
  },
  {
    id: "beacon-mammut-08",
    type: "beacon-mammut",
    label: "Mammut Barryvox 08",
  },
  {
    id: "beacon-mammut-09",
    type: "beacon-mammut",
    label: "Mammut Barryvox 09",
  },
  {
    id: "beacon-mammut-10",
    type: "beacon-mammut",
    label: "Mammut Barryvox 10",
  },
  {
    id: "drytoolboots-39",
    type: "drytoolboots",
    label: "Drytool boty Triop Capoeira 39",
  },
  {
    id: "drytoolboots-41",
    type: "drytoolboots",
    label: "Drytool boty Triop 41",
  },
  { id: "drill", type: "drill", label: "Vrtačka" },
];
